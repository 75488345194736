import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { ContactTitle } from '../components/titles/Title'
import FadeIn from "../components/FadeIn";

const Wrapper = styled.div`
	display:flex;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const LeftText = styled.div`
	flex:1;
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%
	}
`;
const GroupContent = styled.div`
	max-width:250px;
`;

const EmailLink = styled.a`
	color:${props => props.theme.red};
	transition:${props => props.theme.trans};
	&:hover{
		color:${props => props.theme.grey};
	}
`;
const ContactPage = () => (
  <Layout>
    <SEO title="Boxas - Contact" />
    <Wrapper>
			<LeftText>
				<ContactTitle />
				<FadeIn duration="900" delay="1000">
					<h4>GENERAL BUSINESS OPENING HOURS</h4>
					<p>8.30am – 5pm Monday – Thursday</p>
					<p>8.30am – 2.45pm Friday</p>
					<p>Although out of office appointments can be arranged in advance</p>
					<h5>Ways to Contact Us</h5>
					<GroupContent className="email">
						<div>
							<span>Email: <EmailLink href="mailto:neil@boxas.co.uk" title="Email: neil@boxas.co.uk">neil@boxas.co.uk</EmailLink></span>
						</div>
					</GroupContent>
					<GroupContent className="email">
						<div>
							<span>Telephone: <EmailLink href="tel:+441388 609806" title="Telephone: 01388 609806">01388 609806</EmailLink></span>
						</div>
					</GroupContent>
					<GroupContent className="email">
						<div>
							<span>Mobile: <EmailLink href="tel:+447516743649" title="Mobile: 07516 743 649">07516 743 649</EmailLink></span>
						</div>
					</GroupContent>
				</FadeIn>
				
				

			</LeftText>

			
		</Wrapper>
  </Layout>
)

export default ContactPage